import {useKeycloak} from "@react-keycloak/web";
import {useContext} from "react";
import {SettingsHolderContext} from "../components/SettingsHolder";

export function useRolesProvider() {
    const keycloak = useKeycloak();
    const settings = useContext(SettingsHolderContext)
    const manageRole = 'MANAGE'
    const reportRole = 'REPORT'

    function isAdmin() {
        return keycloak.keycloak.hasRealmRole('ADMIN');
    }

    function isOrgAdmin() {
        return keycloak.keycloak.hasRealmRole('ORG_ADMIN') || isAdmin();
    }

    function isManager() {
        return isOrgAdmin() || keycloak.keycloak.hasRealmRole(settings?.inst + "/" + manageRole)
    }

    return {
        isAdmin,
        isOrgAdmin,
        isReportViewer: ()=>{
            return isManager() || keycloak.keycloak.hasRealmRole(settings?.inst+"/"+reportRole)
        },
        isManager: ()=>{
            return isManager()
        },
        hasMultipleOrgs: ()=>{
            return isOrgAdmin() ||
                new Set(
                    keycloak.keycloak.realmAccess?.roles
                        .filter(x=>x.includes("/"))
                        .map(x=>x.split("/")[0])
                ).size>1;
        },
        hasAccessTo: (inst: string)=>{
            return isOrgAdmin() ||
                (keycloak.keycloak.realmAccess?.roles
                    .filter(x=>x.startsWith(inst + "/"))
                    .length??0)>0;

        },
        hasManagerAccessTo: (inst: string)=>{
            return isOrgAdmin() ||
                (keycloak.keycloak.realmAccess?.roles
                    .filter(x=>x===inst + "/" + manageRole)
                    .length??0)>0;

        },
        hasReportViewerAccessTo: (inst: string)=>{
            return isOrgAdmin() ||
                (keycloak.keycloak.realmAccess?.roles
                    .filter(x=>x===inst + "/" + manageRole || x===inst + "/" + reportRole)
                    .length??0)>0;

        }
    }
}
import {useContext, useState} from "react";
import {useApi} from "./ApiProvider"
import {getExtension, processDownload} from "../utils/utils";
import {Button, Dropdown} from "antd";
import {DownloadOutlined, FileExcelOutlined, FileTextOutlined, FileWordOutlined} from "@ant-design/icons";
import {FiltersDto, PersonNameDtoWithId} from "../generated/api";
import {useRolesProvider} from "../utils/keycloakUtils";
import {SettingsHolderContext} from "./SettingsHolder";

interface ExportButtonProps {
    filters: FiltersDto
    person?: PersonNameDtoWithId
}

export function ExportButton(props: ExportButtonProps) {
    const rolesProvider = useRolesProvider()
    const [exportLoading, setExportLoading] = useState(false);
    const settingsHolder = useContext(SettingsHolderContext)
    const api = useApi()

    function getFileName(key: string) {
        const institution = settingsHolder?.settings?.name.replaceAll(/\s*\/\s*/g, ", ")??""
        let result = institution
        //TODO
        // if (props.division) {
        //     if (props.year) {
        //         result = institution + ", " + props.division.title
        //     } else {
        //         result = institution + ", " + props.division.title + ", " + props.year
        //     }
        // } else if (props.journal) {
        //     if (props.year) {
        //         result = institution + ", " + props.journal
        //     } else {
        //         result = institution + ", " + props.journal + ", " + props.year
        //     }
        // } else if (props.year) {
        //     result = institution + ", " + props.year.toString()
        // } else 
        if (props.person) {
            result = props.person?.firstName + " " + props.person?.lastName;
        }
        return result + getExtension(key);
    }

    return <>{rolesProvider.isReportViewer() && <Dropdown menu={{
        items: [{
            key: 'doc',
            label: <>Microsoft Word <span style={{color: '#999'}}>(Bibliography)</span></>,
            icon: <FileWordOutlined style={{fontSize: 16}}/>
        }, ...props.person?.id?[]:[{
            key: 'xls',
            label: <>Excel Spreadsheet <span style={{color: '#999'}}>(By Author)</span></>,
            icon: <FileExcelOutlined style={{fontSize: 16}}/>
        }], {
            key: 'pmid',
            label: <>Plain Text <span style={{color: '#999'}}>(PMIDs Only)</span></>,
            icon: <FileTextOutlined style={{fontSize: 16}}/>
        }],
        onClick: e => {
            setExportLoading(true)
            api.exportData(e.key, props.filters, props.person?.id, {responseType: "arraybuffer"})
                .finally(() => {
                    setExportLoading(false)
                })
                .then(processDownload(getFileName(e.key)))
        }
    }} placement="bottom" arrow={true}>
        <Button icon={<DownloadOutlined/>} size={"small"} style={{marginLeft: 10}}
                type={"link"}
                loading={exportLoading}>Export</Button>
    </Dropdown>}</>
}